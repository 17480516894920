import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../interfaces/config';
import { omit } from 'lodash';
import { lastValueFrom } from 'rxjs';

type ConfigPayload = Omit<Config, 'enableAutomation'> & {
  enableAutomation: string;
};

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  // TODO (Joel): This should be a promise not a raw value as config will go
  // from undefined to Config with no way of waiting for it.
  config: Config;

  constructor(private http: HttpClient) {}

  loadConfig() {
    const time = new Date().getTime();
    const configObservable = this.http.get<ConfigPayload>(
      '/assets/env.json?' + time
    );

    return lastValueFrom(configObservable).then((config) => {
      this.config = {
        ...omit(config, 'enableAutomation'),
        enableAutomation: config.enableAutomation === 'true',
      };
      
      console.table(this.config);
    });
  }
}

export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig();
};
