import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { RecaptchaComponent } from 'ng-recaptcha';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EnvironmentService } from '../../services/env/environment.service';
import { UrlService } from '../../services/url/url.service';
import { SiteRedirectService } from '../../services/site-redirect/site-redirect.service';
import { SentryService } from '../../services/sentry/sentry.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { ConstantCountries } from '../../constants/constant-countries';
import { HttpErrorResponse } from '@angular/common/http';
import { RegistrationService } from './registration.service';
import { RegistrationUser } from './interfaces/registration-user';
import { ConstantFormConstants } from '../../constants/constant-form-constants';
import { trimmedValidator } from '../../validators/trimmed.validator';
import { blockedValuesValidator } from '../../validators/blocked-values.validator';
import {
  businessNameBrandValidator,
  emailBrandValidator,
} from '../../validators/brands.validator';
import { Observable } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { MixpanelService } from '../../services/mixpanel/mixpanel.service';
import { noemojiValidator } from '../../validators/noemoji.validator';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.pug',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  @ViewChild('invisible')
  private invisibleRecaptcha: RecaptchaComponent;

  signUpForm: FormGroup;
  countriesTranslated: Array<object>;
  countriesTranslatedStream: Observable<object>;

  formError: string | null = null;
  hidePassword = true;

  siteLangHomepageUri: string;
  backOfficeUrl: string;
  siteUrl: string;
  blockedCountries = [];

  selectedCountry = '';
  queryParams: ParamMap;

  referralString: string = null;
  partnerName: string = null;

  termsNoAgree = false;

  termsOfUseUrl: URL;
  privacyPolicyUrl: URL;

  isLogged: boolean;
  useCaptcha: boolean;

  constructor(
    private registrationService: RegistrationService,
    private environmentService: EnvironmentService,
    private urlService: UrlService,
    private siteRedirectService: SiteRedirectService,
    private mixpanelService: MixpanelService,
    private sentryService: SentryService,
    private translate: TranslateService,
    private countriesCollection: ConstantCountries,
    private formConstants: ConstantFormConstants,
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute
  ) {
    this.countriesTranslatedStream = this.getCountriesTranslationsStream();
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.setSiteHomepage();
      this.sortCountriesTranslated(this.countriesTranslatedStream);
    });

    this.useCaptcha = !this.environmentService.config.enableAutomation;
  }

  ngOnInit(): void {
    this.sortCountriesTranslated(this.countriesTranslatedStream);
    this.isLogged = this.route.snapshot.data.dataLogged.status;
    this.blockedCountries =
      this.route.snapshot.data.dataLogged.blockedCountries;
    this.setSignUpForm();
    this.backOfficeUrl = this.environmentService.getBackOfficeUrl();
    this.siteUrl = this.environmentService.getSiteUrl();
    this.setAgreementLinks();
    this.setCountryWithGeoIp();
    this.queryParams = this.urlService.getQueryParams();
    this.mixpanelService.track('Sign up form (Backoffice)');
    if (this.queryParams.has('ref')) {
      this.showPartnerName(this.queryParams);
    }

    console.table({
      'logged in': this.isLogged,
      captcha: this.useCaptcha,
    });
  }

  registration(captchaResponse = '') {
    if (this.useCaptcha && !captchaResponse) {
      return;
    }

    this.formError = null;

    const emailControl = this.signUpForm.get('email');
    const countryControl = this.signUpForm.get('selectedCountry');

    const data: RegistrationUser = {
      email: emailControl.value.trim(),
      password: this.signUpForm.get('password').value,
      businessName: this.signUpForm.get('businessName').value,
      country: countryControl.value,
      cabinetLang: this.translate.getDefaultLang(),
      timeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
      tsZoneOffset: new Date().getTimezoneOffset() * 6000 * -1,
      recaptchaResponse: captchaResponse,
    };

    if (this.referralString && this.partnerName) {
      data.referralString = this.referralString;
    }

    this.registrationService.registration(data).subscribe({
      next: (response) => {
        switch (response.regResult) {
          case 'ok':
            this.mixpanelService.identify(String(response.ownerId));
            this.mixpanelService.track('Sign up successful (Backoffice)');
            this.document.cookie = 'user=intercom-session-re5afjv3; max-age=0';
            if (document.referrer.includes('advanced-inventory')) {
              this.document.location.assign(
                this.backOfficeUrl + '/dashboard/#/inventory/invmanagement'
              );
            } else {
              this.goBackOffice();
            }
            break;
          case 'owner_already_exists':
          case 'merchant_already_exists':
            this.formError = 'EMAIL_EXISTS';
            this.invisibleRecaptcha.reset();
            break;
          case 'service_unavailable_in_country':
            countryControl.setErrors({ valueBlocked: true });
            this.invisibleRecaptcha.reset();
            break;
          case 'email_is_blocked':
            this.formError = 'SOMETHING_WRONG';
            this.invisibleRecaptcha.reset();
            break;
          case 'recaptcha_not_verified':
            this.formError = 'RECAPTCHA_NOT_VERIFIED';
            this.invisibleRecaptcha.reset();
            break;
          case 'owner_exists_in_saltId':
            this.formError = 'OWNER_EXISTS_IN_SALTID';
            this.invisibleRecaptcha.reset();
            break;
          default:
            this.invisibleRecaptcha.reset();
        }
      },
      error: (err: HttpErrorResponse) => {
        this.formError = 'UNEXPECTED_ERROR';
        this.invisibleRecaptcha.reset();
        this.sentryService.captureException(err);
        this.sentryService.captureMessage('Server response ' + err.message);
      },
    });
  }

  /**
   * Sets links for text in "I agree" checkbox
   */
  setAgreementLinks() {
    this.termsOfUseUrl = new URL(`${this.siteUrl}/terms-use`);
    this.privacyPolicyUrl = new URL(`${this.siteUrl}/privacy-policy`);
  }

  /**
   * Adds `country` query param to links object
   */
  addCountryToAgreementLinks(country: string) {
    this.termsOfUseUrl.searchParams.set('country', country);
    this.privacyPolicyUrl.searchParams.set('country', country);
    this.translate.use(this.translate.currentLang);
  }

  /**
   * Sets site homepage URL (used for logo)
   */
  setSiteHomepage() {
    const lang = this.document.location.pathname.substring(1).split('/')[0];
    this.siteLangHomepageUri =
      this.siteUrl + this.siteRedirectService.getPathForSite(lang);
  }

  /**
   * Get all countries translated names in stream
   */
  getCountriesTranslationsStream(): Observable<object> {
    const countries = this.countriesCollection.COUNTRIES;
    const translationStrings = countries.map((countryCode) => {
      return 'COUNTRY.' + countryCode.toUpperCase();
    });
    return this.translate.stream(translationStrings);
  }

  /**
   * Sort countries names in stream
   * @param stream - countries translated names stream
   */
  sortCountriesTranslated(stream: Observable<object>) {
    stream.subscribe((translations) => {
      this.countriesTranslated = [];
      for (const key in translations) {
        const lastTwoKeySymbols = key
          .substring(key.length - 2, key.length)
          .toLowerCase();
        this.countriesTranslated.push({
          code: lastTwoKeySymbols,
          name: translations[key],
        });
      }

      this.countriesTranslated.sort((a: object, b: object) => {
        return a['name'].localeCompare(b['name']);
      });
    });
  }

  /**
   * Set Sign Up form
   */
  setSignUpForm() {
    const fragment = this.urlService.getUrlFragment();
    const email = fragment ? fragment : '';
    this.signUpForm = new FormGroup({
      email: new FormControl(email, [
        Validators.required,
        Validators.maxLength(96),
        trimmedValidator(this.formConstants.EMAIL_PATTERN, true),
        trimmedValidator(this.formConstants.EMAIL_DOMAINS_PATTERN, false),
        emailBrandValidator(),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(40),
        Validators.pattern(this.formConstants.PASSWORD_PATTERN),
      ]),
      businessName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.formConstants.BUSINESS_TYPE_PATTERN),
        noemojiValidator(),
        businessNameBrandValidator(),
      ]),
      selectedCountry: new FormControl(this.selectedCountry, [
        Validators.required,
        blockedValuesValidator(this.blockedCountries),
      ]),
      reCaptcha: new FormControl(null),
      checkboxPrivacyPolicy: new FormControl(false, [Validators.requiredTrue]),
    });
  }

  /**
   * Go to Sign In page
   */
  goSignInForm() {
    this.urlService.navigateToState('login');
    return false;
  }

  /**
   * Set country using geoip library
   */
  setCountryWithGeoIp() {
    this.registrationService.getCountryByIp().subscribe({
      next: (res) => {
        if (res['status'] === 'success') {
          const country = res['countryCode'].toLowerCase();
          this.selectedCountry = this.countriesCollection.COUNTRIES.includes(
            country
          )
            ? country
            : '';
        }
      },
      complete: () => {
        this.signUpForm.get('selectedCountry').setValue(this.selectedCountry);
        this.addCountryToAgreementLinks(this.selectedCountry);
      },
    });
  }

  /**
   * Show partner name, only if referral string exists
   * @param queryParams
   */
  showPartnerName(queryParams: ParamMap) {
    this.referralString = queryParams.get('ref');
    this.registrationService
      .getPartnerName({ referralString: this.referralString })
      .subscribe({
        next: (res) => {
          if (res.refResult === 'ok') {
            this.partnerName = res.partnerName;
          } else if (res.refResult === 'wrong_referral_string') {
            this.formError = 'INVALID_REFERRAL_STRING';
          }
        },
        error: (err) => {
          this.formError = 'UNEXPECTED_ERROR';
        },
      });
  }

  /**
   * On form submit method
   * @param signUpNgForm
   */
  onFormSubmit(signUpNgForm) {
    if (
      signUpNgForm.submitted &&
      this.signUpForm.hasError('required', 'checkboxPrivacyPolicy')
    ) {
      this.formError = 'AGREE_TERMS';
      this.termsNoAgree = true;
    } else {
      this.formError = null;
      this.termsNoAgree = false;
    }

    if (signUpNgForm.valid) {
      this.formError = null;

      if (this.useCaptcha) {
        this.invisibleRecaptcha.execute();
      } else {
        this.registration();
      }
    }
  }

  /**
   * Removing error on terms checkbox change
   * @param event
   */
  onTermsCheckboxChange(event) {
    if (
      event.checked &&
      this.formError === 'AGREE_TERMS' &&
      this.termsNoAgree
    ) {
      this.formError = null;
      this.termsNoAgree = false;
    }
  }

  /**
   * Go to backoffice homepage
   */
  goBackOffice() {
    this.document.location.assign(this.backOfficeUrl + '/dashboard/#/home');
  }

  /**
   * Sentry message on captcha error
   * @param error
   */
  onCaptchaError(error) {
    this.sentryService.captureMessage('reCAPTCHA error on Sign Up page');
  }

  showRegistrationForm() {
    this.isLogged = false;
  }
}
