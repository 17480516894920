import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { LoginService } from './login.service';

import { RecaptchaComponent } from 'ng-recaptcha';

import { DOCUMENT } from '@angular/common';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SentryService } from '../../services/sentry/sentry.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EnvironmentService } from '../../services/env/environment.service';
import { UrlService } from '../../services/url/url.service';
import { SiteRedirectService } from '../../services/site-redirect/site-redirect.service';
import { LoginRequestToBackOffice } from './interfaces/login-request-to-back-office';
import { ConstantFormConstants } from '../../constants/constant-form-constants';
import { trimmedValidator } from '../../validators/trimmed.validator';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { CommonRequestsService } from '../../services/common-requests/common-requests.service';
import { MixpanelService } from '../../services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.pug',
  styleUrls: ['./login.component.scss'],
  providers: [LoginService],
})
export class LoginComponent implements OnInit {
  @ViewChild('invisible')
  private invisibleRecaptcha: RecaptchaComponent;

  queryParams: ParamMap;

  backOfficeUrl: string;
  developerUrl: string;
  assetsUrl: string;

  isOauth = false;
  isBO = false;
  isDeveloper = false;

  emailPattern: RegExp;
  formError = '';
  hidePassword = true;

  siteLangHomepageUri: string;

  loginForm: FormGroup;

  requestData: LoginRequestToBackOffice;

  useCaptcha: boolean;

  constructor(
    private loginService: LoginService,
    private environmentService: EnvironmentService,
    private urlService: UrlService,
    private siteRedirectService: SiteRedirectService,
    private mixpanelService: MixpanelService,
    private sentryService: SentryService,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private commonRequestsService: CommonRequestsService,
    private formConstants: ConstantFormConstants,
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute
  ) {
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.setLinks();
    });
    this.emailPattern = formConstants.EMAIL_PATTERN;
    this.useCaptcha = !this.environmentService.config.enableAutomation;
  }

  ngOnInit(): void {
    this.queryParams = this.urlService.getQueryParams();
    this.setLoginFormGroup(this.queryParams);
    this.backOfficeUrl = this.environmentService.getBackOfficeUrl();
    this.developerUrl = this.environmentService.getDeveloperUrl();
    // this.assetsUrl = this.environmentService.getAssetsUrl();
    this.setFormType(this.queryParams);
    this.setLinks();
    // this.redirectIfLogged();
    this.mixpanelService.track('Sign in form (Backoffice)');
  }

  /**
   * Логин OAuth
   * @param captchaResponse ответ капчи
   */
  loginOauth(captchaResponse: string) {
    const formData = new FormData();
    const authorizeUrl = this.environmentService.getAuthorizeUrl();
    formData.append('username', this.requestData.email);
    formData.append('password', this.requestData.password);
    formData.append('recaptchaResponse', captchaResponse);
    formData.append('rememberMe', '' + this.requestData.rememberMe);

    if (!this.requestData.rememberMe) {
      this.localStorageService.setLocalStorageItem('rememberMe', 'false');
    } else {
      this.localStorageService.removeLocalStorageItem('rememberMe');
    }

    this.loginService.loginOauth(formData).subscribe({
      next: (response) => {
        if (response.result === 'ok') {
          this.formError = '';
          const paramsAsString = this.queryParams.keys
            .filter((key: string) => {
              const oauthParams = [
                'scope',
                'client_id',
                'redirect_uri',
                'response_type',
                'state',
              ];
              return oauthParams.indexOf(key) !== -1;
            })
            .map((key: string) => key + '=' + this.queryParams.get(key))
            .join('&');
          const urlAfter = authorizeUrl + '?' + paramsAsString;
          this.document.location.assign(urlAfter);
        } else if (
          response.result === 'error' &&
          response.message === 'user_password_not_found'
        ) {
          this.formError = 'WRONG_EMAIL_PASSWORD';
          this.invisibleRecaptcha.reset();
        }
      },
      error: (err) => {
        this.formError = 'UNEXPECTED_ERROR';
        this.invisibleRecaptcha.reset();
      },
    });
  }

  /**
   * Логин в бэк-офис
   * @param captchaResponse ответ капчи
   */
  loginToBackOffice(captchaResponse: string) {
    const redirectToIntegrations =
      this.queryParams.has('url') &&
      this.queryParams.get('url') === 'integrations-management';

    this.sentryService.setUser({ email: this.requestData.email });

    const data: LoginRequestToBackOffice = {
      ...this.requestData,
      recaptchaResponse: captchaResponse,
      devId: this.getDevId(this.queryParams),
      cabinetLang: this.translate.currentLang,
      type: 'pos',
    };

    if (!this.requestData.rememberMe) {
      this.localStorageService.setLocalStorageItem('rememberMe', 'false');
    } else {
      this.localStorageService.removeLocalStorageItem('rememberMe');
    }

    this.loginService.loginToBackOffice(data).subscribe({
      next: (response) => {
        if (response.result === 'ok') {
          switch (response.loginResult) {
            case 'ok':
              const dontRemember = this.requestData.rememberMe ? 'No' : 'Yes';
              this.mixpanelService.identify(String(response.ownerId));
              this.mixpanelService.track('Sign in successful (Backoffice)', {
                'Do not remember me': dontRemember,
              });
              this.formError = '';

              if (redirectToIntegrations) {
                this.document.location.assign(
                  this.backOfficeUrl + '/dashboard/#/integrations/management'
                );
              } else {
                this.document.location.assign(
                  this.backOfficeUrl + '/dashboard/#/home'
                );
              }
              break;
            case 'user_password_not_found':
              this.formError = 'WRONG_EMAIL_PASSWORD';
              this.invisibleRecaptcha.reset();
              break;
            case 'service_unavailable_in_country':
              this.formError = 'NOT_AVAILABLE_IN_THIS_COUNTRY';
              this.invisibleRecaptcha.reset();
              break;
            case 'recaptcha_not_verified':
              this.formError = 'RECAPTCHA_NOT_VERIFIED';
              this.invisibleRecaptcha.reset();
              break;
            case 'not_subscribed_to_loyverse':
              this.formError = 'NOT_SUBSCRIBED_TO_LOYVERSE';
              this.invisibleRecaptcha.reset();
              break;
            default:
              this.formError = 'UNEXPECTED_ERROR';
              this.invisibleRecaptcha.reset();
          }
        }
      },
      error: (err: HttpErrorResponse) => {
        this.formError = 'UNEXPECTED_ERROR';
        this.invisibleRecaptcha.reset();
        this.sentryService.captureMessage(err.message);
        this.sentryService.captureException(err);
      },
    });
  }

  /**
   * Логин в кабинет разработчика
   * @param captchaResponse ответ капчи
   */
  loginToDevCabinet(captchaResponse: string) {
    const data: LoginRequestToBackOffice = {
      ...this.requestData,
      recaptchaResponse: captchaResponse,
    };

    if (!this.requestData.rememberMe) {
      this.localStorageService.setLocalStorageItem('rememberMe', 'false');
    } else {
      this.localStorageService.removeLocalStorageItem('rememberMe');
    }

    this.loginService.loginToDevCabinet(data).subscribe({
      next: (response) => {
        if (response.result === 'ok') {
          switch (response.loginResult) {
            case 'ok':
              this.formError = '';
              this.document.location.assign(this.developerUrl + '/apps');
              break;
            case 'wrong_password':
              this.formError = 'WRONG_EMAIL_PASSWORD';
              this.invisibleRecaptcha.reset();
              break;
            default:
              this.formError = 'UNEXPECTED_ERROR';
              this.invisibleRecaptcha.reset();
          }
        }
      },
      error: (err: HttpErrorResponse) => {
        this.formError = 'UNEXPECTED_ERROR';
        this.invisibleRecaptcha.reset();
      },
    });
  }

  /**
   * Общая функция логина, логинит либо OAuth либо в бэк-офис
   * @param captchaResponse ответ капчи
   */
  login(captchaResponse: string) {
    if (this.useCaptcha && !captchaResponse) {
      return;
    }

    this.formError = '';

    if (this.isOauth) {
      this.loginOauth(captchaResponse);
    } else if (this.isDeveloper) {
      this.loginToDevCabinet(captchaResponse);
    } else {
      this.loginToBackOffice(captchaResponse);
    }
  }

  /**
   * Определяет тип формы - OAuth, логин в бэк-офис либо в кабинет разработчика
   * @param queryParams параметры запроса
   */
  setFormType(queryParams: ParamMap) {
    if (queryParams.has('app')) {
      const appType = queryParams.get('app');

      if (appType === 'developer') {
        this.isDeveloper = true;
      } else if (appType === 'oauth') {
        this.isOauth = true;
      } else {
        this.isBO = true;
      }
    } else {
      this.isBO = true;
    }
  }

  /**
   * Получение параметра devId в числовом виде
   * @param params параметры запроса
   * @return devId
   */
  getDevId(params: ParamMap): number {
    return parseInt(params.get('devId'), 10);
  }

  /**
   * Получение email пользователя в виде строки
   * @param params параметры запроса
   * @return email пользователя, либо пустая строка, если его нет в параметрах запроса
   */
  getUsername(params: ParamMap): string {
    const email = params.get('email');
    return email !== null ? email : '';
  }

  /**
   * Устанавливает ссылки на восстановление пароля и форму регистрации сайта
   */
  setLinks() {
    const lang = this.urlService.getLangForUrl();
    this.siteLangHomepageUri =
      this.environmentService.getSiteUrl() +
      this.siteRedirectService.getPathForSite(lang);
  }

  /**
   * Редирект в бэк-офис / кабинет разработчика, если пользователь уже залогинен
   */
  redirectIfLogged() {
    if (this.isDeveloper) {
      this.loginService.isDeveloperLogged().subscribe((response) => {
        if (response.logged) {
          this.document.location.assign(this.developerUrl + '/apps');
        }
      });
    } else if (this.isBO) {
      this.commonRequestsService.isCabinetLogged().subscribe((response) => {
        if (response.status) {
          this.document.location.assign(
            this.backOfficeUrl + '/dashboard/#/home'
          );
        }
      });
    }
  }

  setLoginFormGroup(params: ParamMap) {
    const rememberMe = localStorage.getItem('rememberMe');

    this.loginForm = new FormGroup({
      username: new FormControl(this.getUsername(params), [
        Validators.required,
        Validators.maxLength(96),
        trimmedValidator(this.emailPattern, true),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(40),
      ]),
      reCaptcha: new FormControl(null),
      rememberMe: new FormControl(!(rememberMe && rememberMe === 'false')),
    });
  }

  /**
   * Go to Reset Password page
   */
  goResetPassword() {
    this.urlService.navigateToState('resetpswd');
    return false;
  }

  /**
   * Go to registration
   */
  goRegistration() {
    this.urlService.navigateToState('signup');
    return false;
  }

  /**
   * Submit form
   * @param loginNgForm
   */
  onFormSubmit(loginNgForm) {
    if (loginNgForm.valid) {
      this.requestData = {
        email: this.loginForm.get('username').value.trim(),
        password: this.loginForm.get('password').value,
        recaptchaResponse: '',
        rememberMe: this.loginForm.get('rememberMe').value,
      };

      if (this.useCaptcha) {
        this.invisibleRecaptcha.execute();
      } else {
        this.login('');
      }
    }
  }
}
